import React, { FC, useEffect } from 'react'
import { Sandfly, SandflyResponse, updateSandflyResponse } from '@utils/api'
import { Box, Chip, Grid, Stack, Tooltip, Typography } from '@mui/material'
import { Code, Card, Select, Surface, Button } from '@components'
import { Skeleton } from '@mui/material'
import { classifyTags } from '@utils/tags'
import { Create } from '@mui/icons-material'
import GenericPage, { GenericPageProps } from '@templates/Generic/Page'
import useApi, { TUseApiHook } from '@hooks/useApi'
import useForm from '@hooks/useForm'
import useFeatures from '@hooks/useFeatures'

type SandflyViewProps = GenericPageProps & {
  api: TUseApiHook<SandflyResponse>
  id: Sandfly['id']
}

/**
 * Placeholder. Do not use in production
 * @param param0
 * @returns
 */
const SandflyViewTemplate: FC<SandflyViewProps> = ({
  id,
  api,
  children,
  title,
  ...pageProps
}) => {
  const { loading, response, makeRequest } = api
  const form = useForm({
    initialValues: {
      kill: false,
      suspend: false
    }
  })
  const { canUseFeature } = useFeatures()
  const canUseAutoResponse = canUseFeature('sandfly_auto_response')

  const updateApi = useApi({
    apiMethod: updateSandflyResponse,
    requestOnMount: false
  })

  const canUpdate = response?.options?.response?.process
    ? JSON.stringify(form.values) !==
      JSON.stringify(response.options.response.process)
    : false

  const handleUpdate = () => {
    updateApi
      .makeRequest({
        name: id,
        response: form.values
      })
      .then(({ status }) => {
        if (status >= 200 && status <= 299) {
          makeRequest(id)
        }
      })
  }

  // const engines = sandfly ? sandfly.options.engines : []
  const tagStrings = response ? response.tags : []
  const { mitreTechnique, mitreTactic } = classifyTags(tagStrings)
  const sandflyString = response ? JSON.stringify(response, null, 2) : ''
  const isCustom = !!response && !!response.custom
  const hasOptions = !!response?.options?.response?.process || false

  useEffect(() => {
    if (response) {
      const { process } = response.options.response

      if (!process) return

      form.setValues(process)
    }
  }, [response])

  return (
    <GenericPage title={id} metaTitle={`${id} | Sandfly`} {...pageProps}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={7}>
          <Card
            heading="Description"
            headingButton={
              isCustom && (
                <Chip
                  icon={<Create fontSize="small" />}
                  label="Custom Sandfly"
                />
              )
            }
            sx={response && !response.active ? { opacity: 0.5 } : {}}
          >
            <Stack p={2} spacing={1}>
              {response ? (
                <>
                  <Typography variant="h6" style={{ lineBreak: 'anywhere' }}>
                    {response.name}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Version: </strong>
                    {`${response?.version}`}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Status: </strong>
                    {response?.active ? `Active` : `Inactive`}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Type: </strong>
                    {`${response?.type}`}
                  </Typography>
                </>
              ) : (
                <>
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                </>
              )}

              <Box py={2}>
                <Grid container alignItems="center" spacing={1}>
                  {loading ? (
                    <Skeleton height={50} width={150} />
                  ) : (
                    [...mitreTactic, ...mitreTechnique].map(
                      ({ label, tooltip, reference }) => (
                        <Grid item key={label}>
                          <Tooltip title={tooltip}>
                            <Chip
                              component="a"
                              clickable={!!reference}
                              onClick={() => {
                                reference &&
                                  window.open(
                                    `${reference}`,
                                    '_blank',
                                    'noopener'
                                  )
                              }}
                              variant="outlined"
                              label={label}
                            />
                          </Tooltip>
                        </Grid>
                      )
                    )
                  )}
                </Grid>
              </Box>

              <Box>
                {response ? (
                  <Typography variant="body2">
                    {response?.description}
                  </Typography>
                ) : (
                  <>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton width="50%" />
                  </>
                )}
              </Box>
            </Stack>
          </Card>
        </Grid>
        <Grid item xs={12} md={5}>
          <Card
            heading="Auto Response"
            feature="sandfly_auto_response"
            actions={
              hasOptions && (
                <Button
                  feature="sandfly_auto_response"
                  variant="contained"
                  color="primary"
                  disabled={!canUpdate}
                  onClick={() => handleUpdate()}
                >
                  Update
                </Button>
              )
            }
          >
            {!loading && hasOptions ? (
              <Stack p={2} spacing={3}>
                <Select
                  id="kill"
                  form={form as any}
                  label="Kill Process"
                  disabled={
                    response?.type === 'template' || !canUseAutoResponse
                  }
                  options={[
                    {
                      label: 'Active',
                      value: true
                    },
                    {
                      label: 'Off',
                      value: false
                    }
                  ]}
                  helperText="Will kill process if sandfly alerts."
                ></Select>

                <Select
                  id="suspend"
                  form={form as any}
                  label="Suspend Process"
                  disabled={
                    response?.type === 'template' || !canUseAutoResponse
                  }
                  options={[
                    {
                      label: 'Active',
                      value: true
                    },
                    {
                      label: 'Off',
                      value: false
                    }
                  ]}
                  helperText="Will suspend process if sandfly alerts."
                ></Select>
              </Stack>
            ) : (
              <Typography p={2} color="text.secondary">
                No options available{' '}
              </Typography>
            )}
          </Card>
        </Grid>
        <Grid item xs={12}>
          {children}
          <Surface>
            <Code loading={loading} value={sandflyString} />
          </Surface>
        </Grid>
      </Grid>
    </GenericPage>
  )
}

export default SandflyViewTemplate
