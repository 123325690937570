import React, { FC } from 'react'
import {
  activateSandfly,
  deactivateSandfly,
  deleteSandfly,
  getSandfly
} from '@utils/api'
import { navigate, PageProps } from 'gatsby'
import { ButtonGroup } from '@mui/material'
import useApi from '@hooks/useApi'
import { Delete, Edit, FileCopy, Power, PowerOff } from '@mui/icons-material'
import { Button } from '@components'
import SandflyViewTemplate from '@templates/Sandfly/View'
import { WithCaution } from '@components'

const Sandfly: FC<PageProps> = ({ params }) => {
  const api = useApi({
    apiMethod: getSandfly,
    params: params.id
  })

  const { loading, response, error, makeRequest } = api

  const deleteApi = useApi({ apiMethod: deleteSandfly, requestOnMount: false })

  const activateApi = useApi({
    apiMethod: activateSandfly,
    requestOnMount: false
  })

  const deactivateApi = useApi({
    apiMethod: deactivateSandfly,
    requestOnMount: false
  })

  const handleActivation = async () => {
    if (response?.active) {
      await deactivateApi.makeRequest(params.id)
    } else {
      await activateApi.makeRequest(params.id)
    }
    makeRequest(params.id)
  }

  const isCustom = response?.custom

  const handleDelete = () => {
    deleteApi.makeRequest(params.id).then(() => {
      navigate('/sandflies/')
      deleteApi.enqueueSnackbar('Sandfly deleted.', { variant: 'success' })
    })
  }

  return (
    <SandflyViewTemplate
      maxWidth="md"
      title={params.id}
      id={params.id}
      api={api}
      breadcrumbs={[
        {
          title: 'Sandflies',
          to: '/sandflies/'
        },
        {
          title: 'Sandfly Detail'
        }
      ]}
      actions={
        <ButtonGroup
          color="primary"
          variant="outlined"
          aria-label="Sandflies Page Actions"
        >
          <Button
            disabled={loading || !response || response.type === 'template'}
            endIcon={response?.active ? <PowerOff /> : <Power />}
            onClick={handleActivation}
          >
            {response?.active ? 'De-activate' : 'Activate'}
          </Button>
          <Button
            feature="custom_sandflies"
            disabled={loading || !response}
            endIcon={<FileCopy />}
            onClick={() =>
              navigate('/sandflies/add/', {
                state: {
                  template: JSON.stringify(response, null, 2)
                }
              })
            }
          >
            Clone
          </Button>
          {isCustom && (
            <Button
              disabled={loading || !response}
              endIcon={<Edit />}
              to={`/sandflies/edit/${params.id}/`}
            >
              Edit
            </Button>
          )}
          {isCustom && (
            <WithCaution>
              <Button
                disabled={loading || !response}
                endIcon={<Delete />}
                onClick={handleDelete}
              >
                Delete
              </Button>
            </WithCaution>
          )}
        </ButtonGroup>
      }
    >
      {error && <pre>{JSON.stringify(error, null, 2)}</pre>}
    </SandflyViewTemplate>
  )
}

export default Sandfly
